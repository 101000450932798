import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, from, Observable, switchMap, throwError, timer } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from './../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public isExpired = false;

  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._oktaAuth.tokenManager.get('accessToken').then(response=>{
      this.isExpired = this._oktaAuth.tokenManager.hasExpired(response);
    })
    const authToken = this._oktaAuth.getAccessToken();
    if((!authToken || authToken == '') || this.isExpired) {
      return from(this._oktaAuth.signOut()).pipe(
        switchMap(() => {
          return next.handle(this.addAuthHeaderToAllowedOrigins(request, ''));
        }),
        catchError(error => {
          console.error('Sign-out error:', error);
          return next.handle(this.addAuthHeaderToAllowedOrigins(request, ''));
        })
      );
    } else {
      return next.handle(this.addAuthHeaderToAllowedOrigins(request, authToken));
    }
  }

  private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>, authToken: string | undefined): HttpRequest<unknown> {
    let req = request;
    const allowedOrigins = [
      environment.backendConfig.url
    ];
    
    if (!!allowedOrigins.find(origin => request.url.includes(origin))) {
      req = request.clone({ setHeaders: { 'Authorization': `Bearer ${authToken}` } });
    }

    return req;
  }
}
